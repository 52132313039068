import { Modal as ModalUI, Button, Alert } from "flowbite-react";
import React, { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Label, TextInput, Checkbox, Spinner } from "flowbite-react";
import { useForm } from "react-hook-form";
import { useAuth } from "../context/AuthContext";
import logo from "../assets/logo.png";
import Modal from "react-modal";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

export default function LoginModal() {
  const [showModal, setShowModal] = useState(false);

  const { login, getProfile } = useAuth();
  const [success, setSuccess] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const recaptchaRef = useRef(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const navigateAccordingToRole = () => {
    const userInfo = getProfile();
    navigate(
      (() => {
        if ("isAdmin" in userInfo) return "/admin";
        if (userInfo.isModerator) return "/admin";
        return "/user";
      })()
    );
  };

  const onSubmit = async (data) => {
    try {
      // console.log(data);
      setLoading(true); // to avoid multiple submission
      const captchaToken = recaptchaRef.current.getValue();
      recaptchaRef.current.reset();
      const response = await login(data, captchaToken);
      setLoading(false);

      if (response.status == 200) {
        setSuccess(true);
        reset();
      }

      navigateAccordingToRole();
    } catch (err) {
      console.log(err);
      if (!err?.response) {
        setSubmitError("No response from server");
      } else if (err.response?.status == 400) {
        setSubmitError(
          "Missing username or password, or captcha needs to be completed"
        );
      } else if (err.response?.status == 401) {
        setSubmitError("Unauthorized access, please recheck credentials");
      } else if (err.response?.status == 406) {
        setSubmitError("Please verify your account through email");
      } else {
        setSubmitError("Unable to login, please try again");
      }
      setSuccess(false);
      setLoading(false);
    }
  };

  const onError = async () => {
    console.log("Func: onError, FIle: LoginPage.js");
  };

  return (
    <>
      <Button
        pill="true"
        onClick={() => {
          setShowModal(true);
        }}
      >
        Login
      </Button>
      {showModal && (
        // <Modal isOpen={showModal} className="border-none" contentLabel="Login" ariaHideApp={false}>
        <ModalUI
          show={showModal}
          size="md"
          popup={true}
          onClose={() => {
            setShowModal(false);
          }}
          className="backdrop-blur-md"
        >
          <ModalUI.Header />
          <ModalUI.Body>
            <div className="flex flex-col justify-center items-center">
              {/* <div className="flex flex-col justify-center items-center bg-white dark:bg-slate-800 w-96 py-8 rounded-lg shadow-lg"> */}
              <img src={logo} className="w-24 mb-12"></img>

              <h3 className="pb-8 text-xl font-medium text-gray-900 dark:text-white">
                Login to Foodoscope Dashboard
              </h3>

              <form
                className="flex flex-col gap-4 w-4/5"
                onSubmit={handleSubmit(onSubmit, onError)}
              >
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="email1" value="Your email" />
                  </div>

                  <TextInput
                    id="email1"
                    type="email"
                    placeholder="Email address"
                    color={errors.email ? "failure" : null}
                    helperText={
                      errors.email && errors.email.type === "required"
                        ? "Email is required"
                        : errors.email && errors.email.type === "pattern"
                        ? "Enter valid email ID"
                        : null
                    }
                    required={true}
                    {...register("email", {
                      required: true,
                      pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                    })}
                  />
                </div>

                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="password1" value="Your password" />
                  </div>
                  <TextInput
                    id="password1"
                    type="password"
                    placeholder="Password"
                    color={errors.password ? "failure" : null}
                    helperText={
                      errors.password && errors.password.type === "required"
                        ? "Password is required"
                        : errors.password &&
                          errors.password.type === "minLength"
                        ? "Password should be at-least 8 characters long."
                        : null
                    }
                    required={true}
                    {...register("password", {
                      required: true,
                      minLength: 8,
                    })}
                  />
                </div>
                {/* {console.log(submitError)} */}
                {submitError && <Alert color="failure">{submitError}</Alert>}

                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LeP0IkpAAAAAGXRJvBuiv86G5NP_FkstoWWY35C"
                  className="mt-4"
                />

                <Button type="submit" disabled={loading} className="mt-4">
                  {loading ? (
                    <>
                      <Spinner
                        aria-label="Left-aligned spinner example"
                        size="xs"
                      />
                      <span className="pl-3">Submitting</span>
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>

                <Label className="mt-4 text-center">
                  Don't have an account?
                  <Link
                    to="/signup"
                    className="ml-1 text-blue-600 hover:underline dark:text-blue-500"
                  >
                    Sign up
                  </Link>
                </Label>
                <Label className="mt-4 text-center">
                  Forgot Password?
                  <Link
                    to="/forgot"
                    className="ml-1 text-blue-600 hover:underline dark:text-blue-500"
                  >
                    Click here
                  </Link>
                </Label>
              </form>
            </div>
          </ModalUI.Body>
        </ModalUI>
        // </Modal>
      )}
    </>
  );
}
