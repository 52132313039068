import { TypeAnimation } from "react-type-animation";
import { Link, animateScroll as scroll } from "react-scroll";

export default function HeroSection({ content }) {
  return (
    <section id="hero" className="w-full bg-fixed bg-center bg-cover h-screen bg-[url('assets/hero_background.jpg')]">
      <div className="flex items-center justify-center w-full h-full bg-gray-900/60">
        <div className="text-center">
          <h1 className="text-3xl mt-24 font-semibold text-white lg:text-4xl">
            {content.hero.heading}{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 animate-text">
              <TypeAnimation
                sequence={content.hero.typewriterWordsList}
                cursor={true}
                repeat={Infinity}
              />
            </span>
          </h1>
          <p className="mt-4 text-xl text-white">{content.hero.subheading}</p>
          <Link to="about" offset={-87} smooth={true}>
            <button
              type="button"
              class="text-white rounded-full px-5 py-2 mt-8 text-lg font-medium bg-gradient-to-r from-red-500 via-red-500 to-red-800 transition-colors duration-300 transform hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 text-center mr-2 mb-2"
            >
              {content.hero.button}
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}
