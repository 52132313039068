// UserDashboard

import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "../api/axios";
import { Spinner } from "flowbite-react";
// Component import
import ApiInfoModal from "../modals/ApiInfoModal";
import Navigation from "../components/Navigation";
import TokenInfo from "../components/UserDashboardComponents/TokenInfo";
import KeyManagement from "../components/UserDashboardComponents/KeyManagement";
import RequestTokens from "../components/UserDashboardComponents/RequestTokens";
import PaymentDetails from "../components/UserDashboardComponents/PaymentDetails";

import recipeDB_logo from "../assets/recipeDB_logo.png";
import flavorDB_logo from "../assets/flavorDB_logo.png";

const UserDashboard = () => {
  // VARIABLES
  const { fetchProfile, getProfile, getToken, logout } = useAuth();
  const navigate = useNavigate();
  // STATE VARIABLES
  const [fetchSuccess, setFetchSuccess] = useState(false);
  const [apisList, setApisList] = useState([]);
  const [userInfo, setUserInfo] = useState(getProfile());
  const [paymentInfo, setPaymentInfo] = useState({});


  // FUNCTIONS
  const fetchApiList = async () => {
    // fetches the list of apis
    const token = await getToken();
    const result = await axios.get("/apis/list").catch(async (error) => {
      if (error.response.status === 401) {
        logout();
      } else {
        console.log("Unable to fetch APIs");
      }
    });

    setApisList(result.data.apis);
  };


  const fetchPaymentInfo = async () => {
    // fetches payment info data
    fetch("./PAYMENT_INFO.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        setPaymentInfo(json);
      });
  };


  const handleUpdates = async (setRequestedTokens=null, setShowError=null) => {
    // if the user key is successfully generated/deleted
    // update the profile
    await fetchProfile();
    const updatedUserInfo = getProfile();
    setUserInfo(updatedUserInfo);
    if (setRequestedTokens) setRequestedTokens("");
    if (setShowError) setShowError(0);
  };

  // USE EFFECT
  useEffect(() => {
    (async () => {
      if (!userInfo) {
        navigate("/login");
      } else if (userInfo.isModerator) {
        navigate("/admin");
      } else {
        await fetchProfile();
        await fetchApiList();
        await fetchPaymentInfo();
        const updatedUserInfo = getProfile();
        setUserInfo(updatedUserInfo);
        setFetchSuccess(true);
      }
    })();
  }, []);


  const temp_api_data = {
    "recipeDB" : {
      "short_description": "118,000+ recipes from across the globe.",
      "long_description": "Ingredient composition, quantities, nutrition profiles, cuisines, and other attributes of over 118,000 recipes from across 26 world regions.",
      "image": recipeDB_logo,
      "link": "https://documenter.getpostman.com/view/25274592/2s93ecvVAd",
    },
    "flavorDB" : {
      "short_description": "flavor profiles of ~1,000 natural ingredients.",
      "long_description": "Flavor molecule constitution, their taste and odor profiles, nomenclatures, and chemical descriptors of 934 natural ingredients across 36 categories.",
      "image": flavorDB_logo,
      "link": "",
    }
  };

  return (
    <>
      {!fetchSuccess ? (
        <div className="flex items-center justify-center w-screen h-screen">
          <Spinner aria-label="Left-aligned spinner example" size="lg" />
        </div>
      ) : (
        <div className="flex flex-col w-full h-full min-h-screen dark:bg-gray-900 bg-[conic-gradient(at_top,_var(--tw-gradient-stops))] from-rose-100 to-teal-100 dark:from-slate-900 dark:to-slate-800">
          <Navigation stay={true} />
          <div className="flex flex-col items-center w-full px-4 md:px-10 py-10 gap-12 grow">
            <div className="container flex flex-col gap-6 mt-32 max-w-[50rem]">
              <div className="flex flex-col md:flex-row gap-4 ">
                <TokenInfo userInfo={userInfo} />
                <KeyManagement
                  handleUpdates={handleUpdates}
                  userInfo={userInfo}
                />
              </div>
              <RequestTokens
                userInfo={userInfo}
                paymentInfo={paymentInfo}
                handleUpdates={handleUpdates}
              />
              <PaymentDetails paymentInfo={paymentInfo} />
            </div>

            <div className="w-full">
              <p className="text-2xl font-semibold dark:text-white">
                Available APIs
              </p>
              <p className="text-slate-600 dark:text-slate-200 mt-2 mb-8">
                Click on each API for more info
              </p>
              <div className="grow grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 md:gap-8 auto-rows-min">
                {apisList.map((api) => (
                  <ApiInfoModal
                    key={api._id}
                    name={api.name}
                    short_description={api.name=="recipeDB" ? temp_api_data.recipeDB.short_description : temp_api_data.flavorDB.short_description}
                    long_description={api.name=="recipeDB" ? temp_api_data.recipeDB.long_description : temp_api_data.flavorDB.long_description}
                    imageUrl={api.name=="recipeDB" ? temp_api_data.recipeDB.image : temp_api_data.flavorDB.image}
                    link={api.name=="recipeDB" ? temp_api_data.recipeDB.link : temp_api_data.flavorDB.link}
                    subapis={api.subapis}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserDashboard;
