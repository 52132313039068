import React from 'react'
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import apiDoc from "./apidocs.json"
import './swagger_style.css'

import Navigation from "../../components/Navigation";


export default function APIRequests() {

  return (
    <>
    <Navigation stay={false} />
    <div className='py-8 bg-gradient-to-l from-gray-900 via-gray-900 to-blue-900 pl-0 xl:pl-10 text-white min-h-screen'>
      <SwaggerUI spec={apiDoc}/>
    </div>
    </>
  )
}
