// PricingSection

export default function PricingSection({ content }) {
  return (
    <section
      id="pricing"
      className="py-8 bg-gradient-to-l from-white via-orange-50 to-orange-200 dark:bg-gradient-to-l dark:from-gray-900 dark:via-gray-900 dark:to-blue-900 pl-0 xl:pl-10"
    >
      <div className="container px-6 pt-8 py-16 mx-auto">
        <div className="xl:items-center xl:-mx-8 xl:flex">
          <div className="flex flex-col items-center xl:w-1/5 xl:items-start xl:mx-8">
            <h1 className="text-2xl text-center md:text-start font-semibold text-gray-800 capitalize lg:text-3xl dark:text-white">
              {content.pricing.heading}
            </h1>
            <div className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300 text-center md:text-start">
              {content.pricing.subheadings.map((subheading) => (
                <p>{subheading}</p>
              ))}
            </div>
          </div>
          <div className="flex-1 xl:mx-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {content.pricing.plans.map((plan) => (
                <div className="transition ease-in-out duration-300 hover:scale-105 hover:shadow-lg bg-white dark:bg-gray-900 dark:shadow-black/50 mx-auto border rounded-lg md:border dark:border-gray-700 hover:cursor-pointer">
                  <div className="p-6">
                    <h1
                      class={`animate-text text-xl capitalize lg:text-2xl font-bold text-transparent bg-clip-text
                            ${
                              plan.titleGradientColors.length == 1
                                ? `${plan.titleGradientColors[0]}`
                                : `bg-gradient-to-r ${plan.titleGradientColors[0]} ${plan.titleGradientColors[1]} ${plan.titleGradientColors[2]}`
                            }`}
                    >
                      {plan.title}
                    </h1>
                    <p className="text-orange-700 dark:text-blue-300 opacity-50">
                      {plan.subtext}
                    </p>
                    <p className="mt-4 text-gray-500 dark:text-gray-300">
                      {plan.description}
                    </p>
                    {plan.type == "per token" ? (
                      <h2 className="mt-4 text-2xl font-bold text-gray-700 sm:text-3xl dark:text-gray-300">
                        {/* ${plan.price}{" "} */}
                        ${"___ "}
                        <span className="text-base font-medium">per token</span>
                      </h2>
                    ) : (plan.type == "time" && (
                      // <h2 className="mt-4 text-2xl font-semibold text-gray-700 sm:text-3xl dark:text-gray-300">
                      //   {plan.price}
                      // </h2>
                      <h2 className="mt-4 text-2xl font-bold text-grey-700 sm:text-3xl dark:text-gray-300">
                        {/* ${plan.price_annual}{" "} */}
                        ${"___ "}
                        <span className="text-base font-medium">per year</span> {"\n"}
                        {/* <div className="text-base font-medium opacity-50">or ${plan.price_monthly}{" "}per month</div> */}
                        <div className="text-base font-medium opacity-50">or ${"___ "}per month</div>
                      </h2>
                    ))}
                    <p className="mt-1 text-gray-500 dark:text-gray-300">
                      
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
