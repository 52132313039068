import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Spinner } from "flowbite-react";
import axios from "../api/axios";
import Navigation from "../components/Navigation";
import AdminSidebar from "../components/AdminSidebar";
import UsersList from "../components/UsersList";
import PendingList from "../components/PendingList";
import ApiCatalog from "./ApiCatalog";
import logo from '../assets/logo.png';

const AdminDashboard = () => {
  // showType - what to show on the right panel
  // -1 -> loading
  // 0 -> list of users who have request to buy (or get) X amount of tokens (PendingList)
  // 1 -> list of users (UsersList)
  // 2 -> list of apis and their subapis (ApiCatalog)
  const [showType, setShowType] = useState(-1);

  // STATE VARIABLES
  const [users, setUsers] = useState([]); // list of users
  const [apisList, setApisList] = useState([]); // list of apis
  const [currentPage, setCurrentPage] = useState(1);

  // MISC
  const { getToken, getProfile, logout } = useAuth();
  const navigate = useNavigate();
  const userInfo = getProfile();

  // REDIRECTION
  // Redirect without Authorization
  if (!userInfo.isModerator) {
    navigate("/user");
  }

  // FETCHING FUNCTIONS
  // these funcitons fetch the information for state variables above
  const fetchApiList = async () => {
    // fetches the list of apis
    const token = await getToken();
    const result = await axios
      .get("/apis", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          logout();
        } else {
          console.log("Unable to fetch APIs");
        }
      });

    setApisList(result.data.apis);
  };

  const fetchUsers = async () => {
    // fetches the list of users that have registered on the platform
    const token = await getToken();
    const result = await axios
      .get("/users", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          pageSize: 50,
          page: currentPage,
        },
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          logout();
        } else {
          console.log("there is an error");
        }
      });

    // filter that removes the current user and admin
    // from the list of users to be displayed
    let returnedUserData = result.data.users;
    returnedUserData = returnedUserData.filter(
      (obj) => !("isAdmin" in obj) && obj._id != userInfo._id
    );
    setUsers(returnedUserData);
  };

  const handleUpdates = async () => {
    // does a one time update of useState variables
    await fetchUsers();
    await fetchApiList();
  };

  useEffect(() => {
    (async () => {
      // calls and fetchUsers function
      try {
        await fetchUsers();
        await fetchApiList();
        setShowType(4);
      } catch {
        console.log("ERROR: While fetching users on mount");
      }
    })();
  }, []);

  // fetch users on pagecurrentPage={currentPage} setCurrentPage={setCurrentPage} change
  useEffect(
    () => async () => {
      await fetchUsers();
    },
    [currentPage]
  );

  const handleShowType = (val) => {
    // sets the show type
    // show type has been defined at the start of the file
    setShowType(val);
  };

  return (
    <div className="flex flex-col h-screen">
      <Navigation stay={true} />
      <div className="flex flex-col lg:flex-row grow overflow-hidden">
        <AdminSidebar handleShowType={handleShowType} />
        <div className="p-6 w-full h-full overflow-y-scroll bg-white dark:bg-gray-800">
          {(() => {
            switch (showType) {
              case -1:
                return (
                  <div className="flex items-center justify-center w-full h-full">
                    <Spinner
                      aria-label="Left-aligned spinner example"
                      size="lg"
                    />
                  </div>
                );
              case 0:
                return <PendingList />;
              case 1:
                return (
                  <UsersList
                    usersList={users}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    handleUpdates={handleUpdates}
                  />
                );
              case 2:
                return (
                  <ApiCatalog
                    apisList={apisList}
                    handleUpdates={handleUpdates}
                  />
                );
              default:
                return(
                  <div className="h-full flex justify-center items-center">
                    <img src={logo} className="grayscale opacity-20 mix-blend-multiply"/>
                  </div>
                );
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
