import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import Modal from "react-modal";
import { Modal as ModalUI, Button, Alert } from "flowbite-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "../api/axios";

export default function GenerateAPIKeyModal({ handleUpdates }) {
  // this modal opens when the user clicks on 'Generate API Key' button
  // hence, on the first load, its sends request to generate key
  // if successful, display the key
  // else, display the error

  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [key, setKey] = useState("");
  const [copied, setCopied] = useState(false);
  const { getToken, logout } = useAuth();

  const generateAPIKey = async () => {
    // generates API key corresponding to a user
    try {
      const genAPI = async () => {
        const token = getToken();
        const result = await axios
          .post(
            `/profile/key`,
            {},
            {
              headers: {
                // add the authorization
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .catch(async (error) => {
            if (error.response.status === 401) {
              // Unauthorized, token invalid
              console.log(error);
              logout();
            } else {
              setShowError(true);
            }
          });

        // console.log(result.data.apiKey);
        setKey(result.data.apiKey);
      };

      // call the function, update the key, handle the updates
      genAPI();
    } catch {
      console.log("ERROR: While creating API key");
    }
  };

  return (
    <>
      <Button
        size="sm"
        color="success"
        onClick={() => {
          setShowModal(true);
          generateAPIKey();
        }}
      >
        Generate API Key
      </Button>

      {showModal && (
        <Modal
          isOpen={showModal}
          contentLabel="Generate"
          className="border-none"
          ariaHideApp={false}
        >
          <ModalUI
            show={showModal}
            size="3xl"
            onClose={() => {
              handleUpdates();
              setShowModal(false);
              setKey("");
              setCopied(false);
            }}
          >
            <ModalUI.Header>Copy generated API key</ModalUI.Header>
            <ModalUI.Body>
              {key && (
                <div className="w-full h-full flex flex-col gap-8 justify-center items-center">
                  <p className="text-gray-600 dark:text-gray-100">
                    <span className="text-red-600 font-medium">Warning!</span> This key will not be shown again. Make sure
                    you copy and save this key somewhere safe.
                  </p>
                  <div className="flex gap-4 w-full items-center">
                    <div className="border rounded-md w-full px-4 py-2 truncate text-center">
                      <p className="text-lg dark:text-white">{key}</p>
                    </div>
                    <CopyToClipboard text={key} onCopy={() => setCopied(true)}>
                      <Button color={copied ? "success" : "light"}>
                        {copied ? "Copied" : "Copy"}
                      </Button>
                    </CopyToClipboard>
                  </div>
                </div>
              )}

              {showError && (
                <Alert color="failure" className="mt-12">
                  <span>
                    <span className="font-medium">Error!</span> While creating
                    API Key
                  </span>
                </Alert>
              )}
            </ModalUI.Body>
          </ModalUI>
        </Modal>
      )}
    </>
  );
}
