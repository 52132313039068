import { Accordion } from "flowbite-react";

export default function FAQSection({ content }) {
  return (
    <section id="faq" className="bg-white dark:bg-gray-900 pt-12">
      <div className="container max-w-5xl px-6 py-10 mx-auto">
        <h1 className="text-2xl font-semibold text-center text-gray-800 lg:text-3xl dark:text-white">
          Frequently Asked Questions
        </h1>
        <div className="mt-12 space-y-8">
          <Accordion className="dark:bg-gray-900">
            {content.faq.map((faq) => (
              <Accordion.Panel>
                <Accordion.Title>{faq.question}</Accordion.Title>
                <Accordion.Content>
                  <p className="mb-2 text-gray-500 dark:text-gray-400">
                    {faq.answer}
                  </p>
                </Accordion.Content>
              </Accordion.Panel>
            ))}
          </Accordion>
        </div>
      </div>
    </section>
  );
}
