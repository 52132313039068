import { useForm, ValidationError } from "@formspree/react";
import { Alert } from "flowbite-react";
import { HiInformationCircle } from 'react-icons/hi';

export default function ContactSection({ content }) {
	const [state, handleSubmit] = useForm(content.contact.form.key); 

  return (
    <section id="contact" className="bg-white dark:bg-gray-900 px-6">
      <div className="container py-12 mx-auto">
        <div className="lg:flex lg:items-center lg:justify-between lg:-mx-6">
          <div className="lg:w-1/3 lg:mx-6 pl-0 xl:pl-10">
            <h1 className="text-2xl text-center md:text-start font-semibold text-gray-800 capitalize lg:text-3xl dark:text-white">
              {content.contact.heading}
            </h1>
            <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300 text-center md:text-start">
              {content.contact.subheading}
            </p>
            <div className="mt-6 flex flex-col items-center md:items-start text-center md:text-left space-y-8 md:mt-8">
              <p className="flex flex-col md:flex-row items-center md:items-start -mx-2 gap-2 md:gap-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-8 h-8 mx-2 text-orange-500 dark:text-orange-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                <span className="mx-2 text-gray-700 dark:text-gray-400">
                  {content.contact.address}
                </span>
              </p>
            </div>
          </div>

          <div className="mt-8 lg:w-1/2 lg:mx-6">
            <div className="transition ease-in-out duration-300 hover:shadow-lg w-full px-6 py-10 mx-auto overflow-hidden bg-white rounded-lg border dark:border-gray-700 dark:bg-gray-900 lg:max-w-xl shadow-gray-300/50 dark:shadow-black/50">
              <h1 className="text-lg font-medium text-gray-700 dark:text-gray-200">
                {content.contact.form.heading}
              </h1>
              <form className="mt-6" onSubmit={handleSubmit}>
                <div className="flex-1">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm text-gray-600 dark:text-gray-200"
                  >
                    Full Name
                  </label>
                  <input
                    id="name"
                    type="name"
                    name="name"
                    placeholder="Your Name"
                    className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  />
                  <ValidationError
                    prefix="Name"
                    field="name"
                    errors={state.errors}
                    className="text-red-500"
                  />
                </div>
                <div className="flex-1 mt-6">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm text-gray-600 dark:text-gray-200"
                  >
                    Email address <span className="text-red-500">*</span>
                  </label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    placeholder="example@mail.com"
                    className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    required
                  />
                  <ValidationError
                    prefix="*Invalid Email: The value"
                    field="email"
                    errors={state.errors}
                    className="mt-2 text-xs text-red-500"
                  />
                </div>
                <div className="w-full mt-6">
                  <label
                    htmlFor="message"
                    className="block mb-2 text-sm text-gray-600 dark:text-gray-200"
                  >
                    Message <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    className="block w-full h-32 px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md md:h-48 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    placeholder="Write your message here"
                    defaultValue={""}
                    id="message"
                    name="message"
                    required
                  />
                  <ValidationError
                    prefix="Message"
                    field="message"
                    errors={state.errors}
                  />
                </div>
                <button
                  type="submit"
                  disabled={state.submitting}
                  className="z-1 w-full px-6 py-3 mt-6 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                >
                  {content.contact.form.button}
                </button>
              </form>

              {state.succeeded && (
                <Alert color="success" icon={HiInformationCircle} className="mt-6">
                  <span>Your message was sent successfully!</span>
                </Alert>
              )}
              {state.errors.length!==0 && !state.succeeded && (
                <Alert color="failure" icon={HiInformationCircle} className="mt-6">
                  <span>Sorry, your message was not sent succesfully! </span>
                </Alert>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}