import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Dropdown,
  Avatar,
  Button,
  Badge,
  DarkThemeToggle,
  Flowbite,
} from "flowbite-react";
import logo from "../assets/logo.png";
import { useAuth } from "../context/AuthContext";
import { Link as SmoothLink, animateScroll as scroll } from "react-scroll";
import LoginModal from "../modals/LoginModal";

function NavigationLaptop({ stay, userInfo, logout, linkDashboardTo }) {
  return (
    <Navbar
      fluid={true}
      className={`border bg-white ${
        stay
        ? "fixed bg-opacity-75 dark:bg-opacity-75 backdrop-blur-sm dark:backdrop-blur-sm shadow-lg w-[calc(100%-2rem)] p-4 -translate-x-1/2 lg:max-w-7xl left-1/2 top-6 rounded-[30px]"
        : "bg-opacity-75 dark:bg-opacity-75 backdrop-blur-sm dark:backdrop-blur-sm shadow-lg absolute-position w-[calc(100%-2rem)] p-4 -translate-x-1/2 lg:max-w-7xl left-1/2 top-6 rounded-[30px]"
      } z-10`}
    >
      <Navbar.Brand href="/" className="">
        <img src={logo} className="mr-3 h-6 sm:h-9" alt="Flowbite Logo" />
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
        Foodoscope API⠀⠀
        </span>
      </Navbar.Brand>

      {userInfo ? (
        <div className="flex items-center gap-4 md:order-2">
          <Flowbite>
            <DarkThemeToggle />
          </Flowbite>

          <Dropdown arrowIcon={false} inline={true} label={<Avatar rounded />}>
            <Dropdown.Header>
              <span className="block truncate text-sm font-medium mb-2">
                {userInfo.email}
              </span>
              {("isAdmin" in userInfo || userInfo.isModerator) && (
                <Badge
                  color={(() => {
                    if ("isAdmin" in userInfo) return "purple";
                    if (userInfo.isModerator) return "pink";
                  })()}
                  size="sm"
                  className="w-fit"
                >
                  {(() => {
                    if ("isAdmin" in userInfo) return "Admin";
                    if (userInfo.isModerator) return "Moderator";
                  })()}
                </Badge>
              )}
            </Dropdown.Header>
            <Dropdown.Item onClick={logout}>
              <span className="text-red-600 font-bold dark:text-red-500">
                Log out
              </span>
            </Dropdown.Item>
          </Dropdown>
          <Navbar.Toggle />
        </div>
      ) : (
        <div className="flex gap-1 md:gap-3 md:order-2">
          <Flowbite>
            <DarkThemeToggle className="rounded-full" />
          </Flowbite>

          {/* <Link to='/login'>
                <Button color="light" pill="true">
                  Login
                </Button>
              </Link> */}
          <Link to="/signup">
            <Button pill="true" color="light">Signup</Button>
          </Link>
          <LoginModal />
        </div>
      )}

      {userInfo ? (
        <Navbar.Collapse>
          <Navbar.Link>
            <Link to="/" className="hover:cursor-pointer">
              Home
            </Link>
          </Navbar.Link>
          <Navbar.Link>
            <Link
              to={linkDashboardTo(userInfo)}
              className="hover:cursor-pointer"
            >
              Dashboard
            </Link>
          </Navbar.Link>
          <Navbar.Link>
            <a
              href="/recipedb-playground"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:cursor-pointer"
            >
              Recipe DB Playground
            </a>
          </Navbar.Link>
          <Navbar.Link>
            <a
              href="/flavordb-playground"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:cursor-pointer"
            >
              Flavor DB Playground
            </a>
          </Navbar.Link>
        </Navbar.Collapse>
      ) : (
        <Navbar.Collapse>
          <Navbar.Link>
            <SmoothLink to="hero" className="hover:cursor-pointer">
              Home
            </SmoothLink>
          </Navbar.Link>
          <Navbar.Link>
            <SmoothLink
              to="about"
              offset={-87}
              smooth={true}
              className="hover:cursor-pointer"
            >
              About
            </SmoothLink>
          </Navbar.Link>
          <Navbar.Link>
            <SmoothLink
              to="pricing"
              offset={-87}
              smooth={true}
              className="hover:cursor-pointer"
            >
              Pricing
            </SmoothLink>
          </Navbar.Link>
          <Navbar.Link>
            <SmoothLink
              to="faq"
              offset={-87}
              smooth={true}
              className="hover:cursor-pointer"
            >
              FAQs
            </SmoothLink>
          </Navbar.Link>
          <Navbar.Link>
            <SmoothLink
              to="contact"
              offset={-38}
              smooth={true}
              className="hover:cursor-pointer"
            >
              Contact
            </SmoothLink>
          </Navbar.Link>
        </Navbar.Collapse>
      )}
    </Navbar>
  );
}

function NavigationMobile({ stay, userInfo, logout, linkDashboardTo }) {
  return (
    <Navbar
      fluid={true}
      className={`border bg-white ${
        stay
        ? "fixed bg-opacity-75 dark:bg-opacity-75 backdrop-blur-sm dark:backdrop-blur-sm shadow-lg w-[calc(100%-2rem)] p-4 -translate-x-1/2 lg:max-w-7xl left-1/2 top-6 rounded-[30px]"
        : "bg-opacity-75 dark:bg-opacity-75 backdrop-blur-sm dark:backdrop-blur-sm shadow-lg absolute-position w-[calc(100%-2rem)] p-4 -translate-x-1/2 lg:max-w-7xl left-1/2 top-6 rounded-[30px]"
      } z-10`}
    >
      <Navbar.Brand href="/" className="">
        <img src={logo} className="mr-3 h-6 sm:h-9" alt="Flowbite Logo" />
      </Navbar.Brand>

      {userInfo ? (
        <div className="flex items-center gap-2 md:order-2">
          {("isAdmin" in userInfo || userInfo.isModerator) && (
            <Badge
              color={(() => {
                if ("isAdmin" in userInfo) return "purple";
                if (userInfo.isModerator) return "pink";
              })()}
              size="sm"
            >
              {(() => {
                if ("isAdmin" in userInfo) return "Admin";
                if (userInfo.isModerator) return "Moderator";
              })()}
            </Badge>
          )}

          <Dropdown
            arrowIcon={false}
            inline={true}
            label={
              <Avatar
                alt="User settings"
                img="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"
                rounded={true}
              />
            }
          >
            <Dropdown.Header>
              <span className="block truncate text-sm font-medium">
                {userInfo.email}
              </span>
            </Dropdown.Header>
            <Dropdown.Item onClick={logout}>
              <span className="text-red-600 font-bold dark:text-red-500">
                Log out
              </span>
            </Dropdown.Item>
          </Dropdown>
          <Navbar.Toggle />
        </div>
      ) : (
        <div>
          <Navbar.Toggle />
        </div>
      )}

      {userInfo ? (
        <Navbar.Collapse>
          <Navbar.Link>
            <Link to="/" className="hover:cursor-pointer">
              Home
            </Link>
          </Navbar.Link>
          <Navbar.Link>
            <Link
              to={linkDashboardTo(userInfo)}
              className="hover:cursor-pointer"
            >
              Dashboard
            </Link>
          </Navbar.Link>
          <Navbar.Link>
            <Link
              to="/recipedb-playground"
              className="hover:cursor-pointer"
            >
              Recipe DB Playground
            </Link>
          </Navbar.Link>
          <Navbar.Link>
            <Link
              to="/flavordb-playground"
              className="hover:cursor-pointer"
            >
              Flavor DB Playground
            </Link>
          </Navbar.Link>
          <Flowbite>
            <DarkThemeToggle />
          </Flowbite>
        </Navbar.Collapse>
      ) : (
        <Navbar.Collapse>
          <Navbar.Link>
            <SmoothLink to="hero" className="hover:cursor-pointer">
              Home
            </SmoothLink>
          </Navbar.Link>
          <Navbar.Link>
            <SmoothLink
              to="about"
              smooth={true}
              className="hover:cursor-pointer"
            >
              About
            </SmoothLink>
          </Navbar.Link>
          <Navbar.Link>
            <SmoothLink
              to="pricing"
              smooth={true}
              className="hover:cursor-pointer"
            >
              Pricing
            </SmoothLink>
          </Navbar.Link>
          <Navbar.Link>
            <SmoothLink to="faq" smooth={true} className="hover:cursor-pointer">
              FAQs
            </SmoothLink>
          </Navbar.Link>
          <Navbar.Link>
            <SmoothLink
              to="contact"
              smooth={true}
              className="hover:cursor-pointer"
            >
              Contact
            </SmoothLink>
          </Navbar.Link>

          <div className="flex justify-between mt-4">
            <Flowbite>
              <DarkThemeToggle className="rounded-full" />
            </Flowbite>
            <div className="flex flex-row gap-2">
              <LoginModal />
              <Link to="/signup">
                <Button pill="true">Signup</Button>
              </Link>
            </div>
          </div>
        </Navbar.Collapse>
      )}
    </Navbar>
  );
}

export default function Navigation({ stay }) {
  const { getProfile, logout } = useAuth();
  const userInfo = getProfile();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleScreenSize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleScreenSize);
    return () => {
      window.removeEventListener("resize", handleScreenSize);
    };
  }, []);

  const linkDashboardTo = (userInfo) => {
    if ("isAdmin" in userInfo || userInfo.isModerator) {
      return "/admin";
    }
    return "/user";
  };

  return (
    <>
      {screenWidth > 768 ? (
        <NavigationLaptop
          stay={stay}
          userInfo={userInfo}
          logout={logout}
          linkDashboardTo={linkDashboardTo}
        />
      ) : (
        <NavigationMobile
          stay={stay}
          userInfo={userInfo}
          logout={logout}
          linkDashboardTo={linkDashboardTo}
        />
      )}
    </>
  );
}
