import { useState, useMemo, useEffect } from "react";
import { Table, Button, Pagination } from "flowbite-react";
import { useAuth } from "../context/AuthContext";
import axios from "../api/axios";
import DeleteEntityModal from "../modals/DeleteEntityModal";
import SortableTableHeader from "./SortableTableHeader.js";
import ManageEducationPlanModal from "../modals/ManageEducationPlanModal";

function UserPlan({ planName }) {
  // function to select the color of user plan
  let color;
  switch (planName) {
    case "premium":
      color = "indigo";
      break;
    case "educational":
      color = "emerald";
      break;
    case "trail":
      color = "cyan";
      break;
    default:
      color = "black";
      break;
  }
  return (
    <p className={`font-medium text-${color}-600`}>
      {planName.charAt(0).toUpperCase() + planName.slice(1)}
    </p>
  );
}

export default function UsersList({ usersList, currentPage, setCurrentPage, handleUpdates }) {
  const { getToken, getProfile, logout } = useAuth();
  const [users, setUsers] = useState(usersList);
  // headerType indicated the header on which the current sorting rule is based
  // for example if the admin selects to sort users by the table header 'verification status'
  // then headerType becomes 'verified'
  // full list of header names and headerType mapping is given in the headerTypeNameMap
  const [headerType, setHeaderType] = useState("");
  // direction of sorting
  // 1 -> ascending
  // -1 -> descending
  const [direction, setDirection] = useState(1);
  const userInfo = getProfile();
  const headerTypeNameMap = {
    type: "TYPE",
    verified: "VERFICATION STATUS",
    plan: "PLAN",
    email: "USER EMAIL",
    tokens: "TOKENS REMAINING",
  };

  const manageModerator = async function (userID, isMod) {
    // sets or removes a user as Moderator

    try {
      const manageMod = async () => {
        const token = await getToken();
        const result = await axios
          .patch(
            `/users/${userID}`,
            {
              isModerator: isMod,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .catch(async (error) => {
            if (error.response.status === 401) {
              logout();
            }
          });
      };
      await manageMod();
      await handleUpdates();
    } catch {
      console.log("ERROR: While making moderator");
    }
  };

  const showDeleteUserButton = function (user, listedUser) {
    // user: 				the user who is operating the application
    // listedUser: 	user under consideration from list of users
    if ("isAdmin" in user && user.isAdmin) {
      // user is admin
      return true;
    } else if ("isModerator" in user) {
      // user is moderator
      // check if listedUser is moderator, don't show delete button in that case
      if ("isModerator" in listedUser && listedUser.isModerator) return false;
    }
    return true;
  };

  const manageModeratorState = function (user, listedUser) {
    // returns
    // 0 -> don't show the button
    // 1 -> show create moderator
    // 2 -> show remove moderator

    if ("isAdmin" in user && user.isAdmin && listedUser.verified) {
      // only admin can manage moderator
      // if current user is moderator
      // show option to remove
      // else show option to add as moderator
      if ("isModerator" in listedUser && listedUser.isModerator) {
        return 2;
      }
      return 1;
    }
    return 0;
  };

  // TODO make use of useMemo to cache all the sorting
  function handleSorting(headerType, direction, listOfUsers = users) {
    let sortedList;
    if (headerType == "type") {
      sortedList = listOfUsers.toSorted((a, b) => {
        if (a.isModerator && !b.isModerator) {
          return -1;
        } else if (!a.isModerator && b.isModerator) {
          return 1;
        } else {
          return a.email.localeCompare(b.email);
        }
      });
    } else {
      sortedList = listOfUsers.toSorted((a, b) => {
        if (a[headerType] < b[headerType]) {
          return -1;
        } else if (a[headerType] > b[headerType]) {
          return 1;
        } else {
          return a.email.localeCompare(b.email);
        }
      });
    }
    if (direction == -1) sortedList.reverse();
    setUsers(sortedList);
    setHeaderType(headerType);
    setDirection(direction);
  }

  // USE EFFECT
  useEffect(() => {
    setUsers(usersList);
    handleSorting(headerType, direction, usersList);
  }, [usersList]);

  // TODO show plan type
  return (
    <div className="flex flex-col items-center w-full h-full">
      <div className="w-full h-full">
        <div className="text-2xl font-semibold mb-6 dark:text-white">
          All Users
        </div>

        {users.length == 0 ? (
          <div className="flex items-center justify-center h-full w-full text-gray-500">
            <p className="text-2xl">No User Found</p>
          </div>
        ) : (
          <Table hoverable={true} className="w-full">
            {/* Table Head */}
            <Table.Head>
              {Object.keys(headerTypeNameMap).map((type) => (
                <Table.HeadCell>
                  {headerType === type ? (
                    <SortableTableHeader
                      headerType={type}
                      headerFullName={headerTypeNameMap[type]}
                      handleSorting={handleSorting}
                      key={false}
                    />
                  ) : (
                    <SortableTableHeader
                      headerType={type}
                      headerFullName={headerTypeNameMap[type]}
                      handleSorting={handleSorting}
                      key={true}
                    />
                  )}
                </Table.HeadCell>
              ))}
              <Table.HeadCell>
                <span className="sr-only">Options</span>
              </Table.HeadCell>
            </Table.Head>

            {/* Table Body */}
            <Table.Body className="divide-y">
              {users.map((user) => (
                <Table.Row
                  key={user._id}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                >
                  <Table.Cell className="whitespace-nowrap text-gray-900 dark:text-white">
                    {user.isModerator ? (
                      <p className="font-medium text-purple-700">Moderator</p>
                    ) : (
                      <p className="font-medium text-blue-800">User</p>
                    )}
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap text-gray-900 dark:text-white">
                    {user.verified ? (
                      <p className="font-medium text-green-600">Verified</p>
                    ) : (
                      <p className="font-medium text-red-600">Not Verified</p>
                    )}
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap text-gray-900 dark:text-white">
                    <UserPlan planName={user.plan} />
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap text-gray-900 dark:text-white">
                    {user.email}
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap text-gray-900 dark:text-white">
                    {user.tokens}
                  </Table.Cell>
                  <Table.Cell>
                    <div className="flex flex-row-reverse gap-2">
                      {showDeleteUserButton(userInfo, user) && (
                        <DeleteEntityModal
                          entity="user"
                          userID={user._id}
                          handleUpdates={handleUpdates}
                        />
                      )}
                      {manageModeratorState(userInfo, user) > 0 && (
                        <Button
                          size="sm"
                          // gradientDuoTone="purpleToBlue"
                          // outline={manageModeratorState(userInfo, user) != 1}
                          // className="w-44"
                          color={"purple"}
                          className={
                            `w-44
                            ${
                              manageModeratorState(userInfo, user) == 1
                              ? ""
                              : "!bg-opacity-20 !border-2 !border-purple-600 !text-purple-600"
                            }`
                          }
                          onClick={() =>
                            manageModerator(user._id, !user.isModerator)
                          }
                        >
                          {manageModeratorState(userInfo, user) == 1
                            ? "Make Moderator"
                            : "Remove Moderator"}
                        </Button>
                      )}
                      <ManageEducationPlanModal
                        type={"endDate" in user ? "update" : "create"}
                        userInfo={user}
                        endDate={user.endDate}
                        handleUpdates={handleUpdates}
                      />
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>

      <Pagination
        currentPage={currentPage}
        layout="navigation"
        showIcons={true}
        totalPages={100}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
}
