// ExploreSection

import { Spinner, useThemeMode } from "flowbite-react";
import ApiInfoModal from "../../modals/ApiInfoModal";
import recipeDB_logo from "../../assets/recipeDB_logo.png";
import flavorDB_logo from "../../assets/flavorDB_logo.png";


export default function ExploreSection({ content, apisList }) {

  const temp_api_data = {
    "recipeDB" : {
      "short_description": "118,000+ recipes from across the globe.",
      "long_description": "Ingredient composition, quantities, nutrition profiles, cuisines, and other attributes of over 118,000 recipes from across 26 world regions.",
      "image": recipeDB_logo,
      "link": "https://documenter.getpostman.com/view/25274592/2s93ecvVAd",
    },
    "flavorDB" : {
      "short_description": "flavor profiles of ~1,000 natural ingredients.",
      "long_description": "Flavor molecule constitution, their taste and odor profiles, nomenclatures, and chemical descriptors of 934 natural ingredients across 36 categories.",
      "image": flavorDB_logo,
      "link": "",
    }
  };

  return (
    <section id="about" className="bg-white dark:bg-gray-900 pl-0 xl:pl-10">
      <div className="container px-6 py-12 mx-auto">
        <h1 className="text-2xl text-center md:text-start font-semibold text-gray-800 capitalize lg:text-3xl dark:text-white">
          {content.explore.heading}
        </h1>
        <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300 text-center md:text-start">
          {content.explore.subheading}
        </p>
        {apisList.length == 0 ? (
          <div className="flex items-center justify-center w-full h-48">
            <Spinner aria-label="Left-aligned spinner example" size="lg" />
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8 lg:mt-12">
            {apisList.map((api) => (
              <ApiInfoModal
                key={api._id}
                name={api.name}
                short_description={api.name=="recipeDB" ? temp_api_data.recipeDB.short_description : temp_api_data.flavorDB.short_description}
                long_description={api.name=="recipeDB" ? temp_api_data.recipeDB.long_description : temp_api_data.flavorDB.long_description}
                imageUrl={api.name=="recipeDB" ? temp_api_data.recipeDB.image : temp_api_data.flavorDB.image}
                link={api.name=="recipeDB" ? temp_api_data.recipeDB.link : temp_api_data.flavorDB.link}
                subapis={api.subapis}
              />
            ))}
          </div>
        )}
      </div>
    </section>
  );
}
