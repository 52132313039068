import { faBarsStaggered } from "@fortawesome/free-solid-svg-icons";

export default function AuthorizationPage({ children }) {
  return (
    <div className="flex justify-center items-center w-screen h-screen bg-signup bg-cover bg-center filter">
      <div className="flex justify-center items-center w-screen h-screen backdrop-blur-sm backdrop-brightness-50 backdrop-contrast-95">
        <div className="flex justify-center items-center w-screen h-screen dark:bg-gray-900 dark:bg-opacity-75">
          {children}
        </div>
      </div>
    </div>
  );
}
