import { Routes, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import PrivateRoute from "./functions/PrivateRoute";

import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import UserDashboard from "./pages/UserDashboard";
import AdminDashboard from "./pages/AdminDashboard";
import LandingPage from "./pages/LandingPage";
import NotFound from "./pages/NotFound";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ForgotPage from "./pages/ForgotPage";
import SignupPasswordPage from "./pages/SignupPasswordPage";

import RecipeDbPlayground from "./pages/RecipeDbPlayground";
import FlavorDbPlayground from "./pages/FlavorDbPlayground";

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});


function App() {
  return (
    <div>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path="user" element={<UserDashboard />} exact />
          <Route path="admin" element={<AdminDashboard />} exact />
        </Route>
        <Route path="/" element={<LandingPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="signup" element={<SignupPage />} />
        <Route path="verify/*" element={<SignupPasswordPage />} />
        <Route path="resetpassword/*" element={<ResetPasswordPage />} />
        <Route path="forgot/" element={<ForgotPage />} />
        <Route path="notfound" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
        <Route path="recipedb-playground/*" element={<RecipeDbPlayground />} />
        <Route path="flavordb-playground/*" element={<FlavorDbPlayground />} />
      </Routes>
    </div>
  );
}

export default App;
