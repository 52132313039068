import React, { useEffect, useState } from "react";
import Navigation from "../components/Navigation";
import { Spinner } from "flowbite-react";
import axios from "../api/axios";
import { useAuth } from "../context/AuthContext";
// Section import
import ExploreSection from "../components/LandingPageComponents/ExploreSection";
import ContactSection from "../components/LandingPageComponents/ContactSection";
import PricingSection from "../components/LandingPageComponents/PricingSection";
import FAQSection from "../components/LandingPageComponents/FAQSection";
import HeroSection from "../components/LandingPageComponents/HeroSection";
import FooterSection from "../components/LandingPageComponents/FooterSection";
import InfoBar from "../components/LandingPageComponents/InfoBar";

export default function LandingPage() {
  // REACT HOOKS
  const [content, setContent] = useState({}); // content to be filled on landing page
  const [apisList, setApisList] = useState([]);
  const [showInfoBar, setShowInfoBar] = useState(false);
  const { logout } = useAuth();

  // FUNCITONS
  // fetches the list of apis
  const fetchApiList = async () => {
    const result = await axios.get("/apis/list").catch(async (error) => {
      if (error.response.status === 401) {
        logout();
      } else {
        console.log(error.response);
        console.log("Unable to fetch APIs");
      }
    });

    setApisList(result.data.apis);
  };

  // fetches content
  const fetchContent = async () => {
    fetch("./LANDING_PAGE_CONTENT.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        setContent(json);
        setShowInfoBar(json.infoBar.show);
      });
  };

  // USE EFFECT
  useEffect(() => {
    fetchApiList();
    fetchContent();
  }, []);

  return (
    <>
      {!content || Object.keys(content) == 0 ? (
        <div className="flex items-center justify-center w-screen h-screen">
          <Spinner aria-label="Left-aligned spinner" size="lg" />
        </div>
      ) : (
        <main className="min-h-screen flex flex-col">
          <Navigation stay={true} />
          {showInfoBar && <InfoBar content={content} setShowInfoBar={setShowInfoBar} />}
          {/* Following are a list of components of the landing page sections */}
          <HeroSection content={content} />
          <ExploreSection content={content} apisList={apisList} />
          <PricingSection content={content} />
          <FAQSection content={content} />
          <ContactSection content={content} />
          <FooterSection content={content} />
        </main>
      )}
    </>
  );
}
