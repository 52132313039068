import React, { useEffect, useState } from "react";
import axios from "../api/axios";

export default function Validate({ email }) {
  const [counter, setCounter] = useState(30); // countdown timer set to 30 seconds
  const [showResend, setShowResend] = useState(false);

  const handleResend = async (email) => {
    console.log(email);
    setCounter(30);
    setShowResend(false);
    try {
      const response = await axios
        .post(`/verify`, { email })
        .catch(async (error) => {
          // show error in case there is
          console.log(error);
        });
    } catch {
      console.log("ERROR: While resending mail");
    }
  };

  useEffect(() => {
    counter > 0
      ? setTimeout(() => setCounter(counter - 1), 1000)
      : (() => {
          setShowResend(true);
        })();
  }, [counter]);

  return (
    <div className="flex justify-between text-sm gap-4 items-center px-4 py-4 rounded-md bg-blue-50">
      <p className="w-3/4" style={{color: "black"}}>
        Verification mail has been sent to your email account. Close window on success
      </p>
      {!showResend ? (
        <p className="font-medium text-base text-blue-500">{counter}</p>
      ) : (
        <a
          onClick={() => handleResend(email)}
          className="text-blue-600 hover:underline hover:cursor-pointer dark:text-blue-500"
        >
          Resend
        </a>
      )}
    </div>
  );
}
