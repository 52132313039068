import React, { useEffect, useState } from "react";
import { Sidebar, Dropdown } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHistory,
  faNetworkWired,
  faUser,
  faFileCsv,
} from "@fortawesome/free-solid-svg-icons";
import CSVUploadModal from "../modals/CSVUploadModal";

export default function AdminSidebar({ handleShowType }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleScreenSize() {
      setScreenWidth(window.innerWidth);
    }
    
    window.addEventListener("resize", handleScreenSize);
    return () => {
      window.removeEventListener("resize", handleScreenSize);
    };
  }, []);
  
  return (
    <>
      {screenWidth < 1024 ? (
        <div className="p-6 bg-gray-100 dark:bg-slate-700">
          <Dropdown
            label="Dashboard Options"
            dismissOnClick={false}
            placement="bottom"
          >
            <Dropdown.Item
              className="hover:cursor-pointer"
              onClick={() => {
                handleShowType(0);
              }}
            >
              <span className="text-orange-600">Pending Requests</span>
            </Dropdown.Item>
            <Dropdown.Item
              className="hover:cursor-pointer"
              onClick={() => {
                handleShowType(1);
              }}
            >
              All Users
            </Dropdown.Item>
            <Dropdown.Item
              className="hover:cursor-pointer"
              onClick={() => {
                handleShowType(2);
              }}
            >
              API Catalog
            </Dropdown.Item>
          </Dropdown>
        </div>
      ) : (
        <div className="w-fit h-full border-r-2 dark:border-r-slate-600">
          <Sidebar inner="opacity-0" aria-label="Sidebar with multi-level dropdown">
            <Sidebar.Items            >
              <p className="text-center text-xl my-4 dark:text-white hover:cursor-pointer"
                onClick={() => {
                  handleShowType(4);
                }}
              >
                Dashboard Options
              </p>
              <Sidebar.ItemGroup>
                <Sidebar.Item
                  className="hover:cursor-pointer"
                  onClick={() => {
                    handleShowType(0);
                  }}
                >
                  <FontAwesomeIcon icon={faHistory} />
                  <span className="ml-[15px] font-medium">
                    Pending Requests
                  </span>
                </Sidebar.Item>
                <Sidebar.Item
                  className="hover:cursor-pointer"
                  onClick={() => {
                    handleShowType(1);
                  }}
                >
                  <FontAwesomeIcon icon={faUser} />
                  <span className="ml-[18px] font-medium">All Users</span>
                </Sidebar.Item>
                <Sidebar.Item
                  className="hover:cursor-pointer"
                  onClick={() => {
                    handleShowType(2);
                  }}
                >
                  <FontAwesomeIcon icon={faNetworkWired} />
                  <span className="ml-[11px] font-medium">API Catalog</span>
                </Sidebar.Item>

                {/* Collapsable Sidebar, contains CSV Options */}
                <Sidebar.Collapse
                  label={
                    <>
                      <FontAwesomeIcon icon={faFileCsv} />
                      <span className="ml-[11px] font-medium">CSV Options</span>
                    </>
                  }
                >
                  <CSVUploadModal
                    type={1}
                    callToAction="Add to Educational Plan"
                  />
                  <CSVUploadModal
                    type={-1}
                    callToAction="Delete from EduPlan"
                  />
                </Sidebar.Collapse>
              </Sidebar.ItemGroup>
            </Sidebar.Items>
          </Sidebar>
        </div>
      )}
    </>
  );
}
