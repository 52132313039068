import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Label, TextInput, Spinner, Button, Alert } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/logo.png";
import AuthorizationPage from "./AuthorizationPage";

const SignupPage = () => {
  // VARIABLES
  const { signupPassword } = useAuth();
  const navigate = useNavigate();
  const pathname = window.location.pathname.split("/").pop();

  // STATE VARIABLES
  const [submitError, setSubmitError] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const modifiedData = { ...data };
      delete modifiedData.confirm_password;

      setLoading(true); // to avoid multiple submission
      console.log(pathname, modifiedData, " pathname")
      const response = await signupPassword(modifiedData, pathname);
      setLoading(false);

      if (response.status == 200) {
        setSubmitError("");
        reset();
        navigate(
          (() => {
            return "/";
          })()
        );
      }
    } catch (error) {
      if (!error.response) {
        setSubmitError("No response from server");
      } else {
        // console.log(error);
        // console.log(error.response.data.error)
        setSubmitError(error.response.data.error);
      }
      setLoading(false);
    }
  };

  const onError = async () => {
    console.log("Error: While creating password while signup");
  };

  // console.log(errors);

  return (
    <AuthorizationPage>
      <div className="flex flex-col justify-center items-center bg-white w-96 py-8 rounded-lg shadow-lg">
        <img src={logo} className="w-24"></img>
        <h1 className="mb-8 mt-6 font-medium">
          Create a Password for your account
        </h1>
        <form
          className="flex flex-col gap-4 w-4/5"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          {/* Password */}
          <div>
            <div className="mb-2 block">
              <Label htmlFor="password1" value="Your password" />
            </div>
            <div className="relative">
            <TextInput
              id="password1"
              type={passwordVisible ? "text" : "password"}
              color={errors.password ? "failure" : null}
              helperText={
                errors.password && errors.password.type === "required"
                  ? "Password is required"
                  : errors.password && errors.password.type === "minLength"
                  ? "Password should be at-least 8 characters long"
                  : errors.password && errors.password.type === "pattern" 
                  ? "The password must contain at least one letter (upper or lowercase), one digit, and one special character from the set @$!%*#?&."
                  : null
              }
              required={true}
              {...register("password", {
                required: true,
                minLength: 8,
                pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
              })}
            />
              <button
                className="absolute w-16 top-3 right-0 flex items-center justify-center px-4 text-gray-600"
                onClick={() => setPasswordVisible(!passwordVisible)}
              >
                {passwordVisible ? (
                  <FontAwesomeIcon icon={faEye} />
                ) : (
                  <FontAwesomeIcon icon={faEyeSlash} />
                )}
              </button>
            </div>
          </div>

          {/* Confirm Password */}
          <div>
            <div className="mb-2 block">
              <Label htmlFor="password2" value="Confirm password" />
            </div>

            <div className="relative">
              <TextInput
                id="password2"
                type={confirmPasswordVisible ? "text" : "password"}
                color={errors.confirm_password ? "failure" : null}
                helperText={
                  errors.confirm_password &&
                  errors.confirm_password.type === "required"
                    ? "Please enter confirmation password"
                    : errors.confirm_password &&
                      errors.confirm_password.type === "validate"
                    ? errors.confirm_password.message
                    : null
                }
                required={true}
                {...register("confirm_password", {
                  required: true,
                  validate: (val) => {
                    if (watch("password") !== val) {
                      return "Passwords do not match"; // this return value is the message
                    }
                  },
                  // minLength: 8,
                })}
              />
              <button
                className="absolute w-16 top-3 right-0 flex items-center justify-center px-4 text-gray-600"
                onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
              >
                {confirmPasswordVisible ? (
                  <FontAwesomeIcon icon={faEye} />
                ) : (
                  <FontAwesomeIcon icon={faEyeSlash} />
                )}
              </button>
            </div>
          </div>

          <Button type="submit" disabled={loading} className="mt-4">
            {loading ? (
              <>
                <Spinner aria-label="Left-aligned spinner example" size="xs" />
                <span className="pl-3">Submitting</span>
              </>
            ) : (
              "Submit"
            )}
          </Button>

          {/* Error handling */}
          {submitError !== "" ? (
            <Alert color="failure">{submitError}</Alert>
          ) : null}

          <Label className="mt-4 text-center">
            Already have an account?
            <Link
              to="/login"
              className="ml-1 text-blue-600 hover:underline dark:text-blue-500"
            >
              Log in
            </Link>
          </Label>
        </form>
      </div>
    </AuthorizationPage>
  );
};

export default SignupPage;
