// ApiInfoModal

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { faCircleNodes } from "@fortawesome/free-solid-svg-icons";
import { Modal as ModalUI, Badge } from "flowbite-react";

export default function ApiInfoModal({
  name,
  short_description,
  long_description,
  imageUrl = "",
  link,
  subapis,
}) {
  // STATE VARIABLES
  const [showModal, setShowModal] = useState(false);
  const infoVarMap = {
    Description: long_description,
    Documentation: link,
  };

  const containerStyle = {
    overflowX: 'scroll',
  };

  return (
    <>
      <div
        onClick={() => setShowModal(true)}
        className="transition ease-in-out duration-300 hover:scale-105 hover:-translate-y-1 hover:shadow-lg bg-white dark:bg-slate-700 dark:shadow-black/50 p-6 border rounded-xl dark:border-gray-700 hover:cursor-pointer"
      >
        <div className="md:flex md:items-start md:-mx-4">
          {imageUrl ? (
            <img
              className="inline-block rounded-xl md:mx-4 w-14 h-14 object-cover "
              src={imageUrl}
            ></img>
          ) : (
            <span className="inline-block p-3 text-orange-500 bg-orange-100 rounded-xl md:mx-4 dark:text-white">
              <FontAwesomeIcon
                icon={faCircleNodes}
                className="text-orange-500"
                size="2xl"
              />
            </span>
          )}
          <div className="mt-4 md:mx-4 md:mt-0 md:w-4/5">
            <div>
            <h1 className="text-xl truncate font-medium text-gray-700 dark:text-white">
              {name}
            </h1>
            <p className="mt-1 text-gray-500 dark:text-gray-300">
              {short_description}
            </p>
          </div>
          </div>
        </div>
      </div>

      {showModal && (
        <ModalUI
          dismissible={true}
          show={showModal}
          className="backdrop-blur-md"
          onClose={() => {
            setShowModal(false);
          }}
          size="3xl"
        >
          <ModalUI.Header>{name}</ModalUI.Header>

          <ModalUI.Body>
            <div className="flex flex-col gap-6 text-md">
              {Object.keys(infoVarMap).map((info) => (
                <div className="flex flex-col gap-1">
                  <p className="font-medium dark:text-white">{info}</p>
                  <p className="text-slate-700 dark:text-slate-100">
                    {infoVarMap[info] ? infoVarMap[info] : "Not Found"}
                  </p>
                </div>
              ))}

              <div className="flex flex-col gap-1">
                <p className="font-medium dark:text-white">API Endpoints</p>
                <div className="flex flex-row flex-wrap gap-2">
                  {subapis.length > 0 ? (
                    subapis.map((subapi) => (
                      <Badge color="info" size="sm">
                        {subapi.name}
                      </Badge>
                    ))
                  ) : (
                    <span className="text-slate-700 dark:text-slate-100">
                      Not Found
                    </span>
                  )}
                </div>
              </div>

            </div>
          </ModalUI.Body>
        </ModalUI>
      )}
    </>
  );
}
