import fullLightLogo from "../../assets/white-logo.png";
import fullDarkLogo from "../../assets/black-logo.png";

export default function FooterSection({ content }) {
  return (
    <footer className="bg-gray-50 dark:bg-gray-800">
      <div className="container flex flex-col items-center justify-between px-6 py-16 mx-auto space-y-4 sm:space-y-0 sm:flex-row">
        <a href="https://foodoscope.com/">
          <img
            className="w-auto h-7 mix-blend-multiply dark:hidden"
            src={fullLightLogo}
            alt="Foodoscope IIIT Delhi Logo"
          />
          <img
            className="w-auto h-7 hidden dark:block dark:mix-blend-screen"
            src={fullDarkLogo}
            alt="Foodoscope IIIT Delhi Logo"
          />
        </a>
        <p className="text-sm text-gray-600 dark:text-gray-300">
          {content.footer.message}
        </p>
      </div>
    </footer>
  );
}
