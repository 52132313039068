import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Label, TextInput, Spinner, Button, Alert } from "flowbite-react";
import { useForm } from "react-hook-form";
import { useAuth } from "../context/AuthContext";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import AuthorizationPage from "./AuthorizationPage";
import Validate from "../components/Validate";

const SignupPage = () => {
	const { signup } = useAuth();
	const [showValidate, setShowValidate] = useState(false);
	const [submitError, setSubmitError] = useState("");
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const recaptchaRef = useRef(null);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		watch,
	} = useForm();

	const onSubmit = async (data) => {
		try {
			const modifiedData = {
				...data,
			};

			setLoading(true); // to avoid multiple submission
			// handle captcha
			const captchaToken = recaptchaRef.current.getValue();
			recaptchaRef.current.reset();
			const response = await signup(modifiedData, captchaToken);
			setLoading(false);

			if (response.status == 200) {
				setShowValidate(true);
				setSubmitError("");
				reset();
			}
		} catch (error) {
			if (!error.response) {
				setSubmitError("No response from server");
			} else {
				// console.log(error);
				// console.log(error.response.data.error)
				setSubmitError(error.response.data.error);
			}
			setLoading(false);
		}
	};

	const onError = async () => {
		console.log("Error: While sending email for signing up");
	};

	// console.log(errors);

	return (
		<AuthorizationPage>
			<div className="flex flex-col justify-center items-center bg-white w-96 py-8 rounded-lg shadow-lg dark:bg-gray-700 dark:text-white">
				<img src={logo} className="w-24" alt="Logo" />
				<h1 className="mb-8 mt-6 font-medium">
					Signup for a Foodoscope API Account
				</h1>
				<form
					className="flex flex-col gap-4 w-4/5"
					onSubmit={handleSubmit(onSubmit, onError)}>
					{/* Email */}
					<div>
						<div className="mb-2 block">
							<Label htmlFor="email1" value="Your email" />
						</div>
						<TextInput
							className="dark:text-black"
							id="email1"
							type="email"
							color={errors.email ? "failure" : null}
							placeholder="Enter a valid email address"
							helperText={
								errors.email && errors.email.type === "required"
									? "Email is required"
									: errors.email && errors.email.type === "pattern"
									? "Enter a valid email ID"
									: null
							}
							required={true}
							{...register("email", {
								required: true,
								pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
							})}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
					<ReCAPTCHA
						ref={recaptchaRef}
						sitekey="6LeP0IkpAAAAAGXRJvBuiv86G5NP_FkstoWWY35C"
					/>
					<Button type="submit" disabled={loading} className="mt-4">
						{loading ? (
							<>
								<Spinner aria-label="Left-aligned spinner example" size="xs" />
								<span className="pl-3">Submitting</span>
							</>
						) : (
							"Submit"
						)}
					</Button>
					{/* Error handling */}
					{submitError !== "" ? (
						<Alert color="failure">{submitError}</Alert>
					) : null}
					{showValidate && <Validate email={email} />}
					<Label className="mt-4 text-center">
						Already have an account?{" "}
						<Link
							to="/login"
							className="ml-1 text-blue-600 hover:underline dark:text-blue-500">
							Log in
						</Link>
					</Label>
				</form>
			</div>
		</AuthorizationPage>
	);
};

export default SignupPage;
